import React from "react"
import Layout from "../components/layouts/Layout/layout"
import Magazine from "../components/sections/Magazine/Magazine"
import Seo from "../components/seo"
import componentFactory from "../utils/componentFactory"
import "./article-template-markdown.scss"
import "./article-template.scss"

const GroupeCategoryTemplate = ({ pageContext }) => {
  const item = pageContext.item
  const page = pageContext.page.attributes

  const components = page.contenu.map(i => componentFactory(i, pageContext))

  const magazineItems = pageContext.item.articles.map(i => i.attributes)
  const actionItems = pageContext.item.actions.map(i => i.attributes)    

  const chemin = [...page.chemin]

  chemin.push({titre: item.groupe })

  return (
    <>
      <Seo
        description={page.metaDescription}
        title={page.titre}
        lang="fr"
      ></Seo>
      <Layout chemin={chemin}>
        <Magazine
          title={page.titre}
          categories={pageContext.categories}
          selectedGroupe={item.groupe}
          magazineItems={magazineItems}
          actionItems={actionItems}
          baseUrl= {page.url}
        />
        {components}
      </Layout>
    </>
  )
}

export default GroupeCategoryTemplate
